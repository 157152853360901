export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mérkőzés visszautasítása"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejárati idő"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állapot"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás és eladás"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csere"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólió"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ország"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régió"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bid"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adományozó"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba jelentése"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlatípus"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megrendelések"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi megbízások"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyiség"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezárás"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmazás"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A termék eredete"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytassa a"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősítés"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók létrehozása"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés és kereskedés"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetések"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ár tartalmazza az ÁFA-t"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éjszakai üzemmód"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiszta üzemmód"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakciós előzmények"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólió"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Párbajok"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakciós előzmények"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla beállítások"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivált"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kikapcsolt"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje az összes"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyelőre nincsenek értesítések"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem lehet jobb gombbal kattintani"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítás"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakció ellenőrzése"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HÉA"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túl sok kérelmet nyújt be"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérte a napi konzultációs limitet. Ellenőrizze fiókját, hogy elkerülje a további korlátozásokat."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrizze a bejegyzését:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba, próbálja meg később újra"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, jelentkezzen be újra"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, a művelet végrehajtása előtt érvényesítse e-mail címét és telefonját."])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön nem jogosult a művelet végrehajtására"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szolgáltatás nem elérhető, kérjük, forduljon az ügyfélszolgálathoz."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hírfolyam"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, ellenőrizze, hogy az összes kötelező mezőt helyesen töltötte-e ki."])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon nyelvet"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francia"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angol"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanyol"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olasz"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengyel"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Német"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cseh"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dán"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görög"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japán"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreai"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugál"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orosz"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szlovák"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kínai"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probléma bejelentése"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vevő"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladó"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldött kód"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhívjuk figyelmét, hogy a kód 15 percig érvényes."])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cikkek"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szakmai"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, ellenőrizze bejegyzését, vagy lépjen kapcsolatba az ügyfélszolgálattal"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolás"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeres másolat"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figyelmeztetések"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max :"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az oldal látogatásához 18 év felettinek kell lennie"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, ellenőrizze életkorát a belépéshez."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmúltam 18 éves"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18 év alatti vagyok"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az oldalra való belépéssel Ön elfogadja az alábbiakat"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["használati feltételek"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["és"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adatvédelmi irányelvek."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy forradalom önmagában"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szeszes italok vásárlásának, eladásának és kereskedelmének referenciája"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akár whisky-rajongó, akár konyakgyűjtő, akár a tökéletességet kereső szakember, a The Exchange Platform Önnek szól. Optimális biztonság, teljes átláthatóság és alacsony díjak: fedezze fel a szeszes italokkal való kereskedést teljes lelki nyugalommal."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztonságos tranzakciók"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedvezményes díjak 2,5% ÁFÁ-val együtt"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100%-ban ellenőrzött ingyenes számlák"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akár magánszemély, akár szakember, fedezze fel a több mint 70 árforrást tartalmazó összesített árforrást több ezer szeszesital-referenciáról!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre ingyenes fiókot"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás és eladás"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A The Exchange Platformnak köszönhetően folyamatosan vásárolhat vagy adhat el. Könnyen hozzáférhet az összes elérhető ajánlathoz a szabványosított referenciáinknak köszönhetően. Találja meg, amit keres, vagy fejezze ki érdeklődését, egyeztesse a partnerkeresést, takarítson meg időt, és kereskedjen a legjobb áron!"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tőzsde"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi vagyunk az egyetlen olyan platform, amely lehetőséget kínál a palackok cseréjére a fedezett készpénzes megoldásunk segítségével. Nincs partnerkockázat, az Ön pénze biztonságban van a platformon, amíg a palackokat le nem szállítják."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokk kereskedés"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosítson vételi vagy eladási tranzakciót a Block Trade segítségével, amely a The Exchange Platform exkluzív funkciója, amely minden tranzakcióhoz egyedi linket generál."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfóliókezelés"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tapasztaltabb gyűjtők és befektetők számára a The Exchange Platform portfóliókezelő eszközt kínál, amely lehetővé teszi, hogy a platform élő árainak és a historikus adatainknak köszönhetően nyomon kövesse pozícióit és azok értékét. Nincs többé végtelen kérdés az üvegek értékével kapcsolatban, kapjon objektív és agnosztikus képet, mint még soha."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Történelmi áradatok"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeállítottuk az összes lehetséges szeszesital legátfogóbb ártörténetét, az árakkal egészen 2002-ig visszamenőleg! Minden szabványosított és 18 különböző pénznemben elérhető."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bizalom garanciája"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetések a Mangopay nevű fizetési szolgáltatónkon keresztül történnek, hogy biztosítsuk a zökkenőmentes tranzakciókat, nem áll fenn a pénz eltűnésének kockázata, amit soha nem fog elküldeni az ellenfélnek."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A legalacsonyabb díjak a piacon"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nálunk a legalacsonyabbak a díjak a piacon, 2,5% áfával együtt a vevőnek és az eladónak, nincs lista- vagy tartalékolási díj. Csak akkor fizet, ha tényleges tranzakcióra kerül sor."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció előtti anonimitás"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akár magánszemély, akár szakember, platformunk a tranzakciókat megelőző anonimitásnak köszönhetően megoldást jelent napjaink minden problémájára."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési szolgáltatónk által a számla érvényesítését megelőző regisztráció-ellenőrzésnek köszönhetően a platformra nem léphet be fantomszámla, csaló vagy bot. A nyomon követhetőség és a biztonság teljes."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön igényeire szabott ajánlat"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozzon egy közösséghez, hogy megtalálja, kezelje és kereskedjen a szeszes italokkal!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szeszes italok típusa"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palack"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, név, EAN, kulcsszavak..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kereskedés előfizetés nélkül is elérhető!"])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog és áttekintés"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hírfolyam"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Események"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrők visszaállítása"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendezés ábécé sorrendben"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ország"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátumok"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hónap"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szeszes italok típusa"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Események szűrése"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdeti időpont"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végdátum"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a  oldalról"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a  oldalon."])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["közelgő események"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["közelgő események"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves előfizetések"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves árfolyamok"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havi árfolyamok"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hónap ingyenes éves fizetés esetén"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Különleges ajánlat"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szakmai ajánlat"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prémium historikus árak"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azoknak a felhasználóknak, akik teljes körű, időbeli késleltetés nélküli historikus adatokat szeretnének."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["havonta"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évente"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szeszesital-típusonként"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés az összes referenciához"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Történelmi árak időbeli késleltetés nélkül"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejlett statisztikák"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iratkozzon fel egy szeszesital"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szeszes italok választéka :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borok"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiegészítő modul"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapasztalt gyűjtők számára, akik több adatot és egy valódi kezelési eszközt szeretnének."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozzon egy szakemberekből álló közösséghez, hogy elemezhesse, kezelhesse és kereskedhessen szeszes italokkal!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejlett portfóliókezelés"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ezt az ajánlatot"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosárba helyezés"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosár"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A  megtekintése"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havi fizetés"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves fizetés"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részösszeg"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő mintavétel"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HÉA (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tovább a fizetéshez"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az előfizetés már a kosarában van"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön már előfizető"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kosara üres"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza az előfizetésekhez"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolatfelvétel"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlázás"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési mód"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A The Exchange Platformon a tranzakciókat a Mangopay nevű fizetési szolgáltatónkon keresztül bonyolítjuk le."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelkártya"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kártyaszám"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név a fizetési kártyán"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejárati dátum (MM/YY)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztonsági kód"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejezze be a vásárlást"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamarosan"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési összefoglaló"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlási jutalék"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HÉA Vásárlási jutalék"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizető"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kártya ellenőrző kódja (a kártya hátoldalán található, általában 3 számjegyből áll."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A törlést figyelembe vették"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lemondás sikertelen, kérjük, forduljon az ügyfélszolgálathoz"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözöljük a tőzsdei platformon"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés egy zökkenőmentes, biztonságos és szabványosított kereskedési környezethez."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bejelentkezésem mentése"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfelejtette jelszavát?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó visszaállítása"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kap egy e-mailt a jelszó visszaállítására vonatkozó utasításokkal."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adja meg e-mail címét"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó visszaállítása"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kap egy e-mailt a jelszó visszaállítására vonatkozó utasításokkal."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó megerősítése"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrző kód"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettős hitelesítés"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adja meg az e-mailben vagy telefonon kapott kódot"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszónak 7 karaktert kell tartalmaznia, legalább egy nagybetűvel."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, töltse ki helyesen az összes mezőt"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg egy érvényes kódot"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók létrehozása"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilitás"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresztnév"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Születési dátum"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemzetiség"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régió"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ország"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím:"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utcai szám"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítószám"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó:"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó megerősítése"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogadom a"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["használati feltételek"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogadom a"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["használati feltételek"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["és a"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adatvédelmi politika"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de The Exchange Platform."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iratkozzon fel hírlevelünkre"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg e-mail címét"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingyenes fiók létrehozása és folytatás"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció befejezése"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesített fiók létrehozása"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vállalat neve"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes cím"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postai irányítószám"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ország"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szakmai e-mail"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vállalat típusa"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cégszám"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HÉA-szám"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cégem"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános információk"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősítés 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC-ellenőrzés"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banki adatok"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információ:"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banki adatok"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés egy zökkenőmentes, biztonságos és szabványosított kereskedési környezethez."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a felhasználási módot"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A regisztrációt követően a számlatípus nem módosítható."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magánszemélyek"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szakmai"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla személyes használatra"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrzött szakmai fiók"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Általános információk"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, töltse ki az összes alábbi mezőt"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magánszámla"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vállalati számla"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szakmai kategória"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogi forma"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 25%-nál nagyobb részesedéssel rendelkező kedvezményezett tulajdonosok ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adja meg a 25%-nál nagyobb részesedéssel rendelkező egyéb haszonhúzók adatait."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Született a"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lakhely"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" és nemzetiség"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszónak legalább 8 karaktert kell tartalmaznia, beleértve egy nagybetűt, egy kisbetűt, egy számot és egy speciális karaktert."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az e-mail cím és a telefonszám megerősítése"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adja meg az e-mailben kapott kódot"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adja meg a telefonon kapott kódot"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy e-mailt küldtünk Önnek."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS-t küldtünk Önnek."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem kapta meg a kódot?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldje el"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősített"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősítésre várva"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg egy érvényes kódot"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratulálunk, máris ingyenesen hozzáférhet az oldal számos funkciójához!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeretné, ha most ellenőriznék a személyazonosságát, hogy teljes mértékben élvezhesse az élményt?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitelesített fiók létrehozása"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerezzen ingyenes ellenőrzött fiókot"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyazonosságának ellenőrzése"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a dokumentum típusát"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Útlevél"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyi igazolvány"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importáljon fényképet az útleveléből"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importáljon fényképet személyi igazolványa mindkét oldaláról."])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon ki egy fájlt"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Világos és olvasható fájl"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájlméret max. 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, JPEG vagy PNG formátum"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beküldés ellenőrzésre"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az átlagos ellenőrzési idő körülbelül 24 óra."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korlátozott funkcionalitás"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse ki a regisztrációs folyamatot, és férjen hozzá az összes funkcióhoz."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldött dokumentumok"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenleg ellenőrzés alatt áll"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetőség van palackok keresésére és historikus árak megtekintésére."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakciós részhez még nem lehet hozzáférni, és előfizetést sem lehet kötni."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldve"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis kivonat vagy ezzel egyenértékű, három hónapnál nem régebbi dokumentum"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alapszabály másolata"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Útlevél vagy személyi igazolvány, mindkét oldal"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldött dokumentum:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldött dokumentumok:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszautasított"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az elutasítás oka:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyes identitás"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vállalati arculat"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statútumok"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részvényesi nyilatkozat"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címellenőrzés"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tovább a fizetéshez"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen kártyaszám"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresztnév és vezetéknév az érvénytelen kártyán"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen lejárati dátum"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen hitelkártya kód"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC díjak a vállalatok számára"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dokumentumokat sikeresen elküldtük. Az érvényesítésre 24 órán belül sor kerül."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A haszonhúzók nyilatkozata"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az átlagos ellenőrzési idő mindössze néhány perc"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készítsen tiszta, éles képeket:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Győződjön meg róla, hogy a személyazonosító igazolvány vagy útlevél teljesen látható, árnyék vagy tükröződés nélkül, és hogy minden adat olvasható."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompatibilis formátumban küldje el:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használjon JPEG, JPG vagy PNG formátumot, megfelelő felbontással, hogy az információk jól láthatóak legyenek."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekintse meg a teljes dokumentumot :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Győződjön meg róla, hogy minden szükséges oldal külön szerepel."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 személyazonossági igazolást kell hozzáadnia"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az útlevélhez egy nyugtát kell hozzáadni"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fájl neve vagy a dokumentumonkénti 7 MB-os maximális méret túllépésre került"])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banki adatok"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg banki adatait, hogy megkapja az eladásokból származó pénzt."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön adatait ügyfélszolgálatunk ellenőrzi. Kapni fog egy e-mailt, amikor banki adatait érvényesítettük."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, routing szám..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régió"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, töltse ki"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldés"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A The Exchange Platformon a tranzakciókat a Mangopay nevű fizetési szolgáltatónkon keresztül bonyolítjuk le."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A banki adatait elmentettük"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A banki adatait már elküldtük"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratulálunk! A fiókja mostantól ellenőrzött."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palackok böngészése"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés a portfóliómhoz"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korlátlan aktív mód"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostantól a funkciók teljes skáláját elérheti."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzájárulok ahhoz, hogy a fenti telefonszámon üzeneteket kapjak a The Exchange Platformtól. Adatköltségek merülhetnek fel, a leiratkozás lemondásához válaszoljon a STOP gombra."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha elutasítja a 2FA SMS-üzeneteket, nem tudja megerősíteni a fiókját. Telefonszámának megerősítése után bármikor leiratkozhat SMS 2FA rendszerünkön keresztül."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Születési hely"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Születési ország"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET szám"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkennelje be vagy írja be a vonalkódot"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legutóbbi keresések"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje az összes"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["megjelenített termék"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["megjelenített termékek"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel a kereséssel talált termék"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel a kereséssel talált termékek"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["megtalált termék"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["talált termékek"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiányzik egy üveg?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrés"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden megtekintése"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladni"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adományozó"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokk kereskedés"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találtunk a keresésnek megfelelő palackokat."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lásd a keresési előzményeket"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre egy fiókot a korlátlan hozzáférés feloldásához"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingyenes regisztráció"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehhez a funkcióhoz bejelentkezés szükséges"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiányzó üveg"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az üveg neve"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírás"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szükség esetén adja meg a hiányzó palack részleteit"])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírhatja, hogy hol az elutasítás okai"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcionális"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldje el a"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találtunk találatokat."])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi megbízások"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palackozói oldal"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulcsszavak kombinációit használhatja."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A keresés nem nagy- és kisbetű-érzékeny."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs szükség az \"évek\" megadására."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy általános szeszesital-kategória (rum, whisky, konyak stb.) beírása nem hoz eredményt."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, ellenőrizze, hogy legalább egy kulcsszó szerepel-e a keresősávban."])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlatípus"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi megbízások"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irány"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozás dátuma"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Árak"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyiség"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vételi megbízás módosítása"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladási megbízás módosítása"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítsa a blokkkereskedelmet"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap megbízás módosítása"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölt megbízások megjelenítése"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeretnék kapni egy"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van egy"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cserébe"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mire szeretnék cserélni"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen módosított megbízás"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen létrehozott megrendelés"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes összeg :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megbízások száma :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím, ár..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az elutasítás oka"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszautasítás"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehhez a hivatkozáshoz nem találtunk palackot."])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadás a portfólióhoz"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás/eladás"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csere"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palack információk"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker-összetétel"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szeszes italok típusa"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palackozó"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márka"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palackozási év"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartalom:"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palackszám"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előállított palackok száma"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bázis"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyártási módszer"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szeszfőzde státusza"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besorolás"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs rendelés erre az üvegre"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker-összetétel"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktív"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keressen és adjon hozzá egy palackot a táblázathoz"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker vagy terméknév"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön által keresett palackot nem találtuk meg"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékesítés"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Források"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehhez a hivatkozáshoz nem áll rendelkezésre kép"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hordószám"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korábbi árak megtekintése"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élő megbízások"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palackok száma"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vételi megbízás létrehozása"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladási megbízás létrehozása"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker megbízás létrehozása"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csereadó megbízás létrehozása"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokk kereskedés létrehozása"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súlyegység"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egységméret"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékesítés"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízás létrehozása"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéni"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenleg nincsenek megrendelések."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vételi megbízás"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlási ár"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyiség"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciók"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beütemezni egy végdátumot és időpontot?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vételi megbízás létrehozása"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladási megbízás"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Árlista"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyiség"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súly"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosszúság"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szélesség"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasság"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fényképek hozzáadása"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciók"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beütemezni egy végdátumot és időpontot?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladási megbízás létrehozása"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen létrehozott megrendelés"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérését feldolgoztuk. Hamarosan felvesszük Önnel a kapcsolatot"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A betartandó szabályok"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerülje el a megbízási könyv szükségtelen elárasztását"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízásait valódi tranzakciókra korlátozhatja."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerülje el az ismételt elutasításokat"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mérkőzés ismételt visszautasítása rosszindulatúnak vagy felelőtlennek minősülhet. Ügyeljen arra, hogy csak akkor kössön tranzakciókat, ha szándékában áll azokat teljesíteni."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiszteletben tartja a helyreállítási időket"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csomagok ismételt nem időben történő átvételének elmulasztása az eladó számára kellemetlenségeket és további költségeket okozhat."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megbízási könyv integritásának tiszteletben tartása"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megbízási könyv manipulálása vagy manipulálása, az érdeklődés deaktiválása egy mérkőzés elutasítása után stb. megtévesztő gyakorlatnak minősül. Győződjön meg arról, hogy cselekedetei átláthatóak."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Készüljön fel a kapcsolódó költségekre"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyi import- és vámadókat lehet alkalmazni. Győződjön meg róla, hogy felkészült ezek megfizetésére a tranzakció megkötésekor."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontos információk nyújtása"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Győződjön meg róla, hogy az üveg leírása pontos és igaz. Ez segít a vásárlóknak a megalapozott döntés meghozatalában. Adjon meg egy teljes elülső és hátsó fényképet."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartsa tiszteletben az ajánlat részleteit"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elkötelezi magát egy üveg eladására, tartsa be ezt a kötelezettségvállalást. Minden be nem tartott ajánlat következményeket von maga után az eladó számára."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantálja a csomagolás és a tartalom minőségét"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sérült csomagok ismételt kiszállítása (romlás, törés, szivárgás) és a nem megfelelő tartalom hatással van Önre. Ügyeljen arra, hogy gondosan csomagolja be az üvegeket."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási idők betartása"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A határidőre történő szállítás ismételt elmulasztása befolyásolhatja a tranzakciós területhez való hozzáférést. Tervezzen ennek megfelelően a késések elkerülése érdekében."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csomaghivatkozás:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itt részletezheti problémáját, hogy megkönnyítse a jegy megoldását"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csomag átvételének megerősítése"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csomag átvételének visszaigazolásával Ön megerősíti, hogy a termék megfelel az Ön elvárásainak."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszközök"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölt"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várakozás"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítás"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldje el"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítás"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peres ügyek"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befejezett"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási részletek"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomon követési szám"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetős"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kövesse nyomon a csomagomat"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lásd a szállítás részleteit"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállításkor"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, hogy új megbízás létrehozása előtt fogadja el vagy utasítsa el javaslatait."])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Block Trade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi link"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztonságos tranzakció a Mangopay fizetési szolgáltatónkkal"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szállítólevél letöltése"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ellenőrzés során"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Névtelen tranzakció (3 EUR ÁFÁ-val együtt)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel kell töltenie az üveg képeit"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyanazt a képet nem lehet többször feltölteni"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak 7 MB-nál kisebb, .JPG, .JPEG és .png kiterjesztésű képeket fogadunk el."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megbízása nem lehet egy óránál rövidebb ideig érvényes."])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyelőre nincsenek vételi megbízások."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyelőre nincsenek eladási megbízások."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vevő nem fogja tudni a személyazonosságát"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Főoldal gyűjtemény"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csomagját a UPS veszi át otthonról"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begyűjtési dátum"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Első átvételi időpont"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó átvételi időpont"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a megbízás nem felel meg az Ön vagy a partner országpreferenciáinak."])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Képek hozzáadása"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vagy húzza és tegye le"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak .JPG, .PNG és .JPEG (fotónként legfeljebb 7 MB)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérhető váltópontokban"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakciós részhez való hozzáféréshez igazolnia kell személyazonosságát."])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyazonosságom ellenőrzése"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riasztás létrehozása"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailben értesítést kap, ha egy eladási megbízás jön létre."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vételi megbízás létrehozásakor e-mailt kap."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megbízás létrehozásakor e-mailt kap."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riasztás létrehozásához lépjen a kiválasztott termék oldalára."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Árak (opcionális)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön riasztása elkészült"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba a riasztás létrehozásában"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az árnak 50 és 30000 EUR vagy 0 EUR között kell lennie."])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az értesítés szüneteltetése"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaktiválja ezt a riasztást"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kikapcsolt"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Történelmi árak"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá egy palackot"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A grafikonok csak tájékoztató jellegűek. Az esetleges hibákért nem vállalunk felelősséget."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A historikus adataink összesítettek"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["értékesítés"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eladás"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a  oldalról"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["különböző forrásokból."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díjak"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bázis 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vonaldiagram"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyertyás diagram"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafikai opciók"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljesítmény"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a  oldalról"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az árak késleltetve jelennek meg"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hónap"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem állnak rendelkezésre historikus áradatok. Ha még nem vettük fel őket, kérjük, lépjen kapcsolatba az ügyfélszolgálattal, hogy hozzáadjuk őket."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi nézet"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napi"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havi"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heti"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összehasonlítás"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs kiválasztott palack"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az üveg már a charton van"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérte a 6 palackos korlátot a diagramhoz"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozit megtekintése"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba jelentése"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba típusa"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzések"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcionális"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja le a problémát pontosabban itt..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pillanatképek / képek"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. 2 kép."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldje el a"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási részletek"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérését figyelembe vettük"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelenési dátum"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilépési árak"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Párbajok"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match vevő"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match eladó"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogadni és eladni"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tekintse meg a ", _interpolate(_named("num_transac")), " aktuális tranzakcióimat."])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotók megjelenítése"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vételi megbízásom"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladási megbízás"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vevő"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamatban"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vételi megbízás"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szerződő fél"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az eladási megbízásom"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek megjelenítendő találatok."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla létrehozása és a funkciók mindenki számára elérhetőek"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jellemzők:"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrizetlen számla"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrzött fiók"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hírfolyam"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingyenes és korlátlan teljes hozzáférés"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Történelmi árak"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejlett vizualizáció"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 hónapos halasztás"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 hónap halasztás"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólió"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 hónapos halasztott értékelés"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 hónapos halasztott értékelés"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 egyedi ketyegő"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 egyedi ketyegő"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatbázis"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickert naponta"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kereskedés"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív megbízások megtekintése"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs tranzakciós hozzáférés"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az azonosító ellenőrzése magánszemélyek számára ingyenes, szakemberek számára díjköteles. A KYC-t a Mangopay nevű pénzforgalmi szolgáltatónk végzi."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korlátlan teljes hozzáférés"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolt azonosító"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájl"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV vagy Excel fájl importálása"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palack"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki az összes"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes kiválasztás megszüntetése"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palack keresése"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólió"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Útbaigazítás,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letöltés"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájl feltöltése"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vagy húzza és tegye le"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak CSV és XLSX fájlok"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["importmodell"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oszlopok"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos tartási időszak"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólió"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átnevezés"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgás egy másik mappába"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappa átnevezése"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fájl neve szükséges"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A karakterek maximális száma nem haladhatja meg a 30-at"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importőr"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraméterek"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statisztikák"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táblázat oszlopok"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciós táblázat"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciók"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Össze kell hajtani az összes"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bontja ki az összes"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladott készletek megjelenítése"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palackok"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketyerék"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információk"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyiség"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettó ár"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttó ár"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizottság"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díjak"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tárolási hely"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mellékletek"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hol vásárolhat"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájl hozzáadása"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá egy palackot"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szülői mappa neve"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További oszlopok"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oszlopos megrendelés"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az én palackjaim"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás dátuma"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áropciók hozzáadása"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladási dátum"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tőzsdei dátum"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladás"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találtunk a keresésnek megfelelő palackokat."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a szülői mappát"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájlnév"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók létrehozása"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palack cseréje"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leírás"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamatban"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartott palackok"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön birtokában lévő palackok"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi referenciák"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A birtokolt palackok szorozva az adott vételárral."])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A birtokolt palackok szorozva a piaci értékkel"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, a piaci érték és a beszerzési ár közötti különbség szorozva a mennyiséggel."])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi teljesítmény"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi éves hozam"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes palack átlagára"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy vevő részesedésének átlagos időtartama a portfólióban maradása"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az eddig vásárolt palackok"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az eddig eladott palackok"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eddigi összes kiadás"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eddigi összes értékesítés"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesített nyereség az első naptól napjainkig"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kiválasztott időszakban realizált nyereség"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az első naptól számított hozam (csak az eladott palackokat veszi figyelembe)."])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatékony éves hozam (eladott palackok)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi referenciák"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdeti érték pozíció"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi teljesítmény"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves hozam"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Történelmi éves hozam"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Történelmi realizált hozam"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átlagos ár"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Történet"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárolt palackok"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker egyedi"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékelés"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Látens nyereség"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladott palackok"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes kiadás"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes realizált nyereség"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megvalósult nyereség (időszak)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Történelmi realizált hozam"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes értékesítés"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás/eladás időpontja"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettó ár"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az árak nem tartalmazzák a következő árakat."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Árak díjakkal"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Árak ÁFÁ-val"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettó ár"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttó vételár"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttó eladási ár"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tárolási hely"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyiség"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékesítés"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hivatkozási adatok"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztos, hogy törölni szeretné ezt a fájlt?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel fájl"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippek az excel fájlhoz"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelenített oszlopok"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki az összes"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használt tér"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiók létrehozása"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá egy palackot"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájl importálása"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi palack"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi palackok"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi referencia"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi referenciák"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuális dosszié"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgás egy mappába"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["palackok a mappában"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hivatkozások a dossziéban"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgás egy mappába"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díjak"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állományok (nap)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mellékletek"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hol vásárolhat"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az értékesítés helye"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladott mennyiség"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WAB ár"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WASD Awards"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszközosztály"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tovább a terméklaphoz"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó ár"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilitás"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza le az összes"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kiválasztott palack(ok)"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgás"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üveg törlése"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A portfólió jelenleg csak PC-n érhető el, de hamarosan mobilon is elérhető lesz."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólió volatilitás"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szeszes italok volatilitása"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeszfőzde volatilitás"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szüreti volatilitás"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilitási korszak"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólió prémium hamarosan"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lásd a részleteket"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tovább a termékoldalra"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozott fájl"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fájlt módosították"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fájlt törölték"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba a fájl létrehozásában"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A palackot hozzáadták"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A palackot módosították"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A palackot áthelyezték"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen importált fájl"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredeti fájl :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt a mappát és a benne lévő összes palackot mozgatja."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön fájlját áthelyeztük"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetetlen törölni, mivel ebben a mappában palackok vannak"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajánlat küldése"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak a 3 Mb-nál kisebb .jpg, .jpeg és .png fájlok engedélyezettek."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakciós részletek"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendelési összefoglaló"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási költségek"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adó"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladni"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszautasítás"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladó :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási díjak"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakciós díjak"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes összeg"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HÉA-val együtt"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS szállítási díjak"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési mód"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési mód"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS biztosítás"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS otthon"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérhető pénzkivételi pontok"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentse el ezt a címet a jövőbeli vásárlásokhoz"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakció nem sikerült. Kérjük, próbálja meg újra."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakció sikeresen lezárult"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetését ellenőrzik"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, várjon néhány másodpercet"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla beállítások"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés kezelése"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetéseim"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési mód"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák előzményei"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fiókom"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás és eladás"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banki adatok"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztonság és 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információ:"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cég"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilitás"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemzetiség"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresztnév"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Születési dátum"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím:"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítószám"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régió"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ország"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újabb szállítási cím hozzáadása"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási cím"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási cím törlése"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlázási cím"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldve"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs információ"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlatulajdonos"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldve"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változtassa meg RIB-jét"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettős hitelesítés"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden egyes bejelentkezéskor és minden egyes alkalommal, amikor a számláján változás történik, kódot kap."])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettős hitelesítés e-mailben"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettős hitelesítés SMS-ben"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legújabb kapcsolatok"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden eszközön megtekintheti a fiókjához való legutóbbi 10 csatlakozás előzményeit."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hírlevél fogadása"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betéti kártya sz."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáadva"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejár a"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkártya módosítása"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön még nem regisztrált fizetési kártyát."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítsa be ezt a címet alapértelmezettként"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedvelt szállítási cím"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az e-mail cím módosítása"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi e-mail cím"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új e-mail cím"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A telefonszám változása"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi telefonszám"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új telefonszám"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási cím hozzáadása"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztráció"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hibás e-mail"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helytelen telefonszám"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön már rendelkezik ezzel az e-mail címmel"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a telefonszám már megvan"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktuális hónap kiadásai"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizető óta"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön nem előfizető"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éves előfizetések"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő gyűjtési dátum"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetett, amíg"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiratkozás"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fedezze fel a rendelkezésre álló historikus árelőfizetéseket"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leiratkozás"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előfizetés a következő időpontban jár le"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az érintett előfizetés"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősíti a törlést"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytassa az előfizetést"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés folytatása"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A következő kifizetés a"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az érintett előfizetés"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetés minden"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az árfolyamon"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fellendülés megerősítése"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kötelezettségvállalás, amíg"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hónap"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prémium historikus árak"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák előzményei"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letöltés minden"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Árak"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állapot"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előfizetés"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla letöltése"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetős"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem rendelkezik számlával"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási részletek"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kövesse nyomon a csomagot"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Becsült szállítás"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció száma"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adja meg az e-mailben kapott kódot"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adja meg a telefonon kapott kódot"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új e-mail"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új telefon"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be az e-mailben kapott kódot"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be a telefonon kapott kódot"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla és szállítási cím"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön preferenciái sikeresen regisztrálva lettek"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Születési hely"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Születési ország"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értesítések"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vételi megbízás létrehozása"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladási megbízás létrehozása"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csereügylet létrehozása"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade megbízás létrehozása"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy vevő elutasította a mérkőzést"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényesítésre váró eladási megbízás"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS fogadásának engedélyezése (számlaérvényesítés és/vagy 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikációs preferenciák"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS Relay Point"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlási lehetőségek"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékesítési lehetőségek"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vételi láthatóság"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az értékesítés láthatósága"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kizárandó országok"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A következő országok"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlási preferenciáim másolása az értékesítéshez"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön módosításait figyelembe vettük"])}
    }
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közösségi hálózatok"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Támogatás"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogi"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súgóközpont"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GYIK"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toborzás"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hibajelentés"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szolgáltatások állapota"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogi információk"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FELTÉTELEK ÉS KIKÖTÉSEK"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie-k kezelése"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franciaországban bejegyzett vállalat"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási díjak"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adók"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vásárlás"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszautasítás"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendelési összefoglaló"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladott rész :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállítási díjak"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Értékesítési díjak"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes összeg"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folyamatban lévő peres ügyek"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vita bejelentése"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakciókat nem végeznek."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrők visszaállítása"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közzétéve: :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítva: :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cikkek szűrése"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találtunk tételeket."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérhető mennyiség"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várakozáson"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes összeg"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszavétel"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgások"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem kell tranzakciókat megjeleníteni."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transzfer"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejövő átutalás"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kimenő átutalás"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eladói fizetés"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszavétel"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszavétel a bankszámlámra"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rendelkezésre álló pénzeszközök visszavonása"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszavétel megerősítése"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az átutalás a bankszámlájára sikeresen megtörtént"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az átutalás a számlájára meghiúsult. Ha a probléma továbbra is fennáll, kérjük, forduljon az ügyfélszolgálathoz."])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárcám (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakció után fennmaradó összeg :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beérkezett fizetés"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés a kereskedési előzményekhez"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési díjak elkerülése érdekében közvetlenül a pénztárcájába utalhat pénzt."])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulajdonos :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénzeszközök 2-3 munkanapon belül rendelkezésre állnak."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen lemásolt elem"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolás"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca-ellátás"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális szűrők"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrés"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kulcsszavak nélküli kereséshez 2 szűrő szükséges"])}
  }
}