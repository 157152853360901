export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝匹配"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买卖"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交换"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出价"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["询问"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赠送者"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受者"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告错误"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户类型"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前订单"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新设置"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品来源"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续浏览"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站名称"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索与交易"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格包含增值税"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夜间模式"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除模式"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易历史"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匹配"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易历史"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户设置"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已激活"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已停用"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部删除"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂时没有通知"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您不能右键单击"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交货"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查交易"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增值税"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的请求太多"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已达到每日咨询上限。请检查您的账户，以免受到进一步限制。"])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查您的条目："])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误，稍后再试"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再次登录"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在执行此操作前验证您的电子邮件和电话"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您无权执行此操作"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务不可用，请联系客户服务"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新闻提要"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请检查所有必填字段是否填写正确"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择语言"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法语"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西班牙语"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息技术"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意大利语"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波兰语"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["德国"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["捷克语"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丹麦语"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["希腊"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匈牙利"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匈牙利语"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韩国"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易平台"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["葡萄牙语"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英国"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["俄罗斯"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斯洛伐克"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文网站"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告问题"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买家"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖家"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送代码"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请注意，代码有效期为 15 分钟"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文章"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业人士"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部内容"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请检查您的条目或联系客服"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功复制"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器 ："])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大值 ："])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须年满 18 岁才能访问该网站"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请检查您的年龄以便进入。"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我已年满 18 岁"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我未满 18 岁"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问本网站即表示您同意以下条款"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用条款和条件"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策。"])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一场革命"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买卖和交易烈酒的参考资料"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无论您是威士忌爱好者、白兰地收藏家还是追求完美的专业人士，交易平台都是您的理想选择。最佳的安全性、完全的透明度和低廉的费用：发现完全放心的烈酒交易。"])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全交易"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["降低费用 2.5%（含增值税"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% 经过验证的免费账户"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无论您是个人还是专业人士，都可以浏览我们汇集的 70 多个价格来源，了解数千种烈性酒的参考价格！"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建免费账户"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买卖"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过交易所平台，您可以持续进行买卖。借助我们的标准化参考资料，您可以轻松获取所有可用报价。找到您正在寻找的产品或表达您的兴趣，匹配交易对手搜索，节省时间并以最佳价格进行交易！"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易所"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们是唯一一个可以使用我们的抵押现金解决方案进行酒瓶交换的平台。没有交易对手风险，在酒瓶交付之前，您的资金在平台上是安全的。"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗交易"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过 \"区块交易 \"确保买入或卖出交易。\"区块交易 \"是交易所平台独有的功能，可为每笔交易生成唯一链接。"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合管理"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于经验丰富的收藏家和投资者来说，交易平台提供了一个投资组合管理工具，通过平台上的实时价格和我们的历史数据，您可以监控您的仓位及其价值。您不再需要无休止地询问您的酒瓶价值，而是可以获得前所未有的客观、中立的观点。"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史价格数据"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们为所有可能的烈酒编制了最全面的历史价格，价格可追溯到 2002 年！所有信息都是标准化的，可以用 18 种不同的货币进行查询。"])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信心保证"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款通过我们的支付服务提供商 Mangopay 进行，以确保交易顺利进行，不会有资金消失的风险，也不会将资金发送给交易对手。"])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场上最低的费用"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的费用是市场上最低的，买方和卖方均为 2.5%（含增值税），无挂牌费或预订费。您只需在有效交易时支付费用。"])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易前匿名"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无论您是个人还是专业人士，我们的平台都能解决当今的所有问题，这得益于其交易前的匿名性。"])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在账户验证之前，我们的支付服务提供商会对注册进行控制，因此不会有虚假账户、骗子或机器人进入平台。具有全面的可追溯性和安全性。"])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为您量身定制的服务"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入社区，查找、管理和交易您的烈酒！"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烈酒类型"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒瓶"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码、名称、EAN、关键字..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易无需订阅！"])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["博客与评论"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新闻提要"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置过滤器"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站名称"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按字母顺序排序"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维尔"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月份"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烈酒类型"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选事件"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束日期"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["近期活动"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["近期活动"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年度订阅"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年费率"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月价格"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年费可免费使用 1 个月"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特别优惠"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业报价"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["溢价历史价格"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适用于需要完整历史数据且无时滞的用户。"])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每年"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按烈酒类型分类"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问所有参考资料"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无时滞历史价格"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级统计数据"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅烈酒"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒类选择 ："])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["葡萄酒"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["补充模块"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适合希望获得更多数据和真正管理工具的资深收藏者。"])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入专家社区，分析、管理和交易您的烈酒！"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级投资组合管理"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择此优惠"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到购物篮"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篮子"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月付款"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年度付款"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小计"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个样品"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增值税（20）"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续付款"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此订阅已在您的购物篮中"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已经是订阅者"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的购物篮是空的"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回订阅"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计费"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易所平台上的交易通过我们的支付服务提供商 Mangopay 进行。"])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡号"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付卡上的姓名"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日期（月/年）"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全代码"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成购买"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即将推出"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款摘要"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买佣金"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增值税 购买佣金"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅者"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行卡验证码（在银行卡背面，一般为 3 位数"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已考虑到取消"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消失败，请联系客服"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎访问交易平台"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问无缝、安全和标准化的交易环境。"])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存我的登录信息"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码重置"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将收到一封电子邮件，内含如何重置密码的说明。"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的电子邮件地址"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码重置"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将收到一封电子邮件，内含如何重置密码的说明。"])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["双重认证"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入通过电子邮件或电话收到的代码"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的密码必须包含 7 个字符，其中至少有一个大写字母。"])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请正确填写所有字段"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效代码"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文明"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站名称"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生日期"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["街道编号"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮编"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维尔"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我接受"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用条款和条件"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我接受"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用条款和条件"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de The Exchange Platform."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅我们的时事通讯"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的电子邮件"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建我的免费账户并继续"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成注册"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取验证账户"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细地址"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维尔"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业电子邮件"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司类型"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司编号"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增值税号"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的公司"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般信息"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC 检查"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行详情"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行详情"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问无缝、安全和标准化的交易环境。"])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择用途"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册完成后，账户类型不可更改。"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业人士"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人账户"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证专业账户"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般信息"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写以下所有字段"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私人账户"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司账户"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业类别"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法律形式"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持有 25% 以上股份的受益所有人？"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请提供持股超过 25%的其他实际所有人的详细信息。"])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["诞生于"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居住地"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和国籍"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码必须至少包含 8 个字符，包括一个大写字母、一个小写字母、一个数字和一个特殊字符。"])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认您的电子邮件和电话号码"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入通过电子邮件收到的代码"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入通过电话收到的代码"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已收到一封电子邮件。"])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已向您发送短信。"])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有收到代码？"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送至"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待确认"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效代码"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恭喜您，您已经可以免费使用该网站的许多功能！"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想现在就验证身份，享受完整的体验吗？"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取验证账户"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取免费验证账户"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份验证"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文件类型"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["护照"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从护照中导入照片"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导入身份证正反两面的照片"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文件"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件清晰可读"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件最大 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG、JPEG 或 PNG 格式"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交验证"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均验证时间约为 24 小时。"])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能有限"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成注册程序，即可使用所有功能。"])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送文件"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前正在检查"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以搜索酒瓶并查看历史价格。"])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还不能访问交易部分或进行订阅。"])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送地址"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis 摘要或不足三个月的同等文件"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司章程副本"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["护照或身份证正反两面"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件已发送："])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送文件："])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝理由"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人身份"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企业形象"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法规"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股东声明"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址验证"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续付款"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效卡号"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效卡上的名字和姓氏"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日期无效"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡代码无效"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司的 KYC 费用"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件已成功发送。验证将在 24 小时内进行。"])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受益所有人声明"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均验证时间仅需几分钟"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拍摄清晰锐利的照片："])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确保身份证或护照清晰可见，没有阴影或反光，所有细节都清晰可辨。"])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以兼容格式发送："])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请使用 JPEG、JPG 或 PNG 格式，分辨率要足够高，以便信息清晰可见。"])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看完整文件 ："])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确保单独包含所有必要的页面。"])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须添加 2 项身份证明"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您需要为护照添加收据"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的文件名或每个文件的最大容量已超过 7MB"])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行详情"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的银行信息，即可收到销售所得。"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的详细信息将由我们的客户服务部门进行验证。您的银行信息通过验证后，您将收到一封电子邮件。"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC、路由号码..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易所平台上的交易通过我们的支付服务提供商 Mangopay 进行。"])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的银行信息已被保存"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的银行信息已发送"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恭喜您！您的账户已通过验证。"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浏览酒瓶"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问我的投资组合"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无限活跃模式"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您现在可以访问全部功能。"])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意通过上述电话号码接收来自交易所平台的信息。可能会收取数据费，请回复 \"STOP \"取消订阅。"])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您拒绝接受 2FA 短信，您将无法确认您的账户。在通过我们的 SMS 2FA 系统确认您的电话号码后，您可以随时取消订阅。"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["诞生地"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生国家"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET 号码"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扫描或输入条形码"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近搜索"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部删除"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示产品"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示产品"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过此搜索找到的产品"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过此搜索找到的产品"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到的产品"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到的产品"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缺少酒瓶？"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选条件"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看全部"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受者"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赠送者"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗交易"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站名称"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到符合您搜索条件的酒瓶。"])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看搜索历史"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户，无限制访问"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费注册"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此功能需要登录"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丢失的酒瓶"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒瓶名称"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["说明"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如有必要，请注明丢失酒瓶的详细信息"])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以描述拒绝的原因"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选项"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网址"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到结果。"])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前订单"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["装瓶商页面"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以使用关键词组合。"])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索不区分大小写。"])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无需指定 \"年份\"。"])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入普通烈酒类别（朗姆酒、威士忌、白兰地等）不会产生任何结果。"])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请检查搜索栏中是否至少有一个关键词。"])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户类型"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前订单"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易方向"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改买入订单"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改卖出订单"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改大宗交易"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改掉期订单"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示已删除订单"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我希望收到"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我拥有一个"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["换取"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我想交换什么"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功修改订单"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功创建订单"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额 ："])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单数量 ："])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题、价格..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝原因"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到相关酒瓶。"])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加到投资组合"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买卖"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交换"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗交易"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒瓶信息"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["综合代号"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烈酒类型"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸馏器"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["装瓶商"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用年限"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年份酒"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["装瓶年份"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瓶数"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产瓶数"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产方式"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒厂现状"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分类"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这瓶酒没有订单"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["综合代号"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索并在图表中添加酒瓶"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际标准书号、股票代码或产品名称"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到您要找的酒瓶"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资料来源"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有相关图片"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒桶编号"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看历史价格"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实时订单"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瓶数"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建购买订单"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建卖出订单"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建掉期承接订单"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建 Swap Giver 订单"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建大宗交易"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重量单位"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位规模"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建订单"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["混合"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前没有订单。"])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买订单"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买价格"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期权"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安排结束日期和时间？"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建购买订单"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖出订单"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格表"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权重"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长度"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宽度"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加照片"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期权"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安排结束日期和时间？"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建卖出订单"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功创建订单"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的请求已处理。我们将尽快与您联系"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作为一个"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["避免不必要的订单泛滥"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订单仅限于真实交易。"])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["避免重复拒绝"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反复拒绝匹配会被视为恶意或不负责任。请确保您只在打算完成交易的情况下进行交易。"])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尊重恢复时间"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屡次不按时收取包裹会给卖方带来不便，并产生额外费用。"])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尊重订单簿的完整性"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伪造或操纵订单簿、在匹配被拒绝后取消兴趣等行为都被视为欺骗行为。确保您的行为是透明的。"])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备好相关费用"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可能需要缴纳当地进口税和关税。请确保您在完成交易时准备好支付这些税费。"])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供准确信息"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确保酒瓶描述准确真实。这有助于买家做出明智的决定。提供完整的正面和背面照片。"])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尊重报价详情"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您承诺出售一瓶酒，请履行这一承诺。任何不兑现的报价都将对卖方产生后果。"])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保证包装及其内容物的质量"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复交付损坏的包裹（变质、破损、泄漏）和不正确的内容会对您造成影响。请务必仔细包装您的酒瓶。"])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遵守交货时间"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多次未能按时发货可能会影响您进入交易区。请做好相应计划，避免延误。"])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装参考："])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在此详细说明您的问题，以便解决票据问题"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收到包裹"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收到包裹即表示您确认产品符合您的期望。"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匹配"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运输"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送至"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交货"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交付"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["诉讼"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交货详情"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跟踪号码"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付费"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跟踪我的包裹"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看交货详情"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交货"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在创建新订单前接受或拒绝您的建议"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接 Block Trade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独特链接"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用我们的支付提供商 Mangopay 进行安全交易"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载交割单"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匿名交易（3 欧元，含增值税）"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须上传酒瓶的图片"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同一图片不能放置多次"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只接受小于 7MB 且扩展名为 .JPG、.JPEG 和 .png 的图片。"])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订单有效期不能少于一小时"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂时没有购买订单。"])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂时没有卖出订单。"])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买家不会知道您的身份"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页收藏"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的包裹由 UPS 从您家中收取"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏日期"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首次取货时间"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后取货时间"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该订单与您或对手方的国家偏好不符"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加图片"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或拖放"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅限 .JPG、.PNG 和 .JPEG（每张照片最大 7MB）"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用中继点"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须验证身份才能访问交易部分"])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查我的身份"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建警报"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建卖出订单后，您将收到一封电子邮件。"])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建买入订单后，您将收到一封电子邮件。"])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建订单后，您会收到一封电子邮件。"])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要创建警报，请进入您选择的产品页面。"])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格（可选）"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的警报已创建"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建警报出错"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的价格必须在 50 至 30000 欧元或 0 欧元之间。"])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂停此通知"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新激活此警报"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已停用"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史价格"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加酒瓶"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图表仅供参考。对于任何错误，我们概不负责。"])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的历史数据汇总"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不同来源。"])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资料来源"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["费用"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均价格"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回顶部"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线形图"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蜡烛图"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图表选项"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒类"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["业绩"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格显示延迟时间为"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月份"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有历史价格数据。如果我们尚未收录，请联系支持部门添加。"])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前视图"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日报"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每月"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年度"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每周"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比较"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择酒瓶"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这瓶酒已经在图表上"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已达到 6 瓶的图表上限"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看综合"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告错误"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误类型"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选项"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在此处更准确地描述问题..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["屏幕截图/图片"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多 2 张图片。"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交货详情"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的要求已被考虑"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布日期"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出价格"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匹配"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匹配买家"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匹配卖家"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受和出售"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["查看我的 ", _interpolate(_named("num_transac")), " 当前交易"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示图片"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的买单"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖出订单"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买家"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买订单"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易对手"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的卖单"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无匹配显示。"])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户创建和功能对所有人开放"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能介绍"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未经审计的账户"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证账户"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新闻提要"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费、无限制的全面访问"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史价格"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级可视化"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 个月延期"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["延期 12 个月"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 个月递延估值"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 个月递延估值"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 个独特的股票代码"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 个独特的股票代码"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据库"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每天 50 条交易信息"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看有效订单"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无交易权限"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人可免费进行身份验证，专业人士则需付费。KYC 由我们的支付服务提供商 Mangopay 执行。"])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无限制完全访问"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制的 ID"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导入 CSV 或 Excel 文件"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒瓶"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择所有"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部取消选择"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索酒瓶"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为您提供指导、"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传文件"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或拖放"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅限 CSV 和 XLSX 文件"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进口模式"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专栏"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均持有期"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动到另一个文件夹"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名文件夹"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件名为必填项"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大字符数不得超过 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站名称"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进口商"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["统计数据"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格栏目"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期权表"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期权"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折叠全部"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展开全部"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示已售出股票"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒瓶"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["净价"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总价"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佣金"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["费用"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存储地点"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["控股公司"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附件"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买地点"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加文件"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加酒瓶"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父文件夹名称"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他栏目"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专栏订单"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的酒瓶"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买日期"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加价格选项"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售日期"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易日期"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到符合您搜索条件的酒瓶。"])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择上级文件夹"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件名"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["换瓶"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["说明"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持有瓶数"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您拥有的酒瓶"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独特的参考资料"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有的酒瓶数乘以相应的购买价格"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有瓶数乘以市场价值"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours，市场价值与购买价格之差乘以数量。"])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前业绩"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前年回报率"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有酒瓶的平均价格"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买方持有的酒在投资组合中的平均停留时间"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您目前购买的酒瓶"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前已售出的酒瓶"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迄今为止的总支出"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迄今为止的销售总额"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从第一天至今的累计收益"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选期间的已实现收益"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收益从第一天开始计算（仅考虑已售出的酒瓶）"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效年收益率（售出瓶数）"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独特的参考资料"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初始价值头寸"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前业绩"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年收益率"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史年回报率"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史实现回报"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均价格"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买瓶数"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码独特"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["估值"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["潜在收益"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售出酒瓶"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总支出"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实现总收益"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已实现收益（期间）"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史实现回报"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售总额"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买卖日期"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["净价"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格不含税。"])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格与费用"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["含增值税价格"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["净价"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买总价"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总售价"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存储地点"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考详细信息"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除此文件吗？"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel 文件"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有关 Excel 文件的提示"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示栏目"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择所有"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用空间"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加酒瓶"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导入文件"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前酒瓶"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前酒瓶"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前参考"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前参考"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前档案"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动到文件夹"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件夹中的酒瓶"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["档案中的参考资料"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动到文件夹"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["费用"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持有量（天）"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附件"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买地点"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售地点"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售数量"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WAB 价格"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WASD 奖项"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产类别"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转到产品介绍"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后价格"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波动性"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消选择所有"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选酒瓶"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除酒瓶"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合目前只在个人电脑上提供，但很快将在手机上提供。"])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合波动"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烈酒的波动性"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒厂波动"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年份波动"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波动时代"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资组合溢价即将推出"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看详情"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转到产品页面"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建的文件"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件已修改"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件已删除"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建文件出错"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒瓶已添加"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒瓶经过改装"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒瓶已被移动"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件已成功导入"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原始文件 ："])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将移动此文件夹及其中的所有酒瓶。"])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的文件已被移动"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法删除，因为此文件夹中有酒瓶"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送报价"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["只允许使用 3Mb 以下的 .jpg、.jpeg 和 .png 文件。"])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易详情"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单摘要"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运费"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税务"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖家 ："])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交付费用"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易费用"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括增值税"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS 配送费"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS 保险"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS 在家"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可提款点数"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存此地址，以便将来购买"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易失败。请重试"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易已成功完成"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的付款正在审核中"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请稍候"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户设置"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅管理"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的订阅"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票历史"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的账户"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买卖"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行详情"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全和 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司介绍"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文明"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站名称"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生日期"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮编"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维尔"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加另一个送货地址"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交货地址"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除送货地址"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单地址"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送至"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无信息"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户持有人"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送地址"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改您的 RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["双重认证"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每次登录和更改账户时都会收到一个代码"])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件双重认证"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短信双重认证"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新连接"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在所有设备上查看最近 10 次连接到您账户的历史记录。"])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收时事通讯"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["借记卡号"])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加日期"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更换银行卡"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您尚未注册支付卡。"])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将此地址设为默认"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首选交货地址"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改电子邮件地址"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前电子邮件地址"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新电子邮件地址"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改电话号码"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前电话号码"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新电话号码"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加送货地址"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件不正确"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码不正确"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已经拥有此电子邮件地址"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已经有了这个电话号码"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当月支出"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户自"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您不是订阅者"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年度订阅"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一次收集日期"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅至"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退订"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索可用的历史价格订阅"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退订"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订阅将于"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相关订阅"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认取消"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简历订阅"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续订阅"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一次付款将在"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["相关订阅"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认复苏"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承诺直至"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月份"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答案"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["溢价历史价格"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票历史"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载全部"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载发票"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付费"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您没有发票"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交货详情"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["跟踪套餐"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预计交货时间"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易编号"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入通过电子邮件收到的代码"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入通过电话收到的代码"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新电子邮件"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新电话"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入通过电子邮件收到的代码"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入通过电话收到的代码"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票和交货地址"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的偏好已成功注册"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生城市"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生国家"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建买入订单"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建卖出订单"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建交换订单"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建大宗交易订单"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买家拒绝了匹配"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待验证的销售订单"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授权接收短信（账户验证和/或 2FA）"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交流偏好"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站名称"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS 中继点"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买选择"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售选择"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买能见度"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售能见度"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不包括的国家"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括的国家"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制我的销售购买偏好"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的更改已被考虑在内"])}
    }
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社交网络"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法律条款"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助中心"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招聘信息"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告错误"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务状况"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法律信息"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条款和条件"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["饼干管理"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在法国注册的公司"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交付费用"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税费"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单摘要"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售部分 ："])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交付费用"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["销售费用"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感官"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在进行的诉讼"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["声明争议"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不进行交易。"])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置过滤器"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布于 ："])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改于 ："])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选文章"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到任何项目。"])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用金额"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搁置"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提款"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无交易显示。"])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转让"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转入"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转出"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖家付款"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提款"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提款到我的银行账户"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提取可用资金"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认提款"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向您的银行账户转账已成功完成"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["向您的账户转账失败。如果问题仍然存在，请联系客户服务部"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的钱包 (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易后剩余金额 ："])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到付款"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问我的交易历史"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接向钱包转账，避免支付费用"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有者 ："])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN ："])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC ："])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的资金将在 2 至 3 个工作日内到账。"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元素复制成功"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包供应"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级过滤器"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无关键字搜索需要 2 个过滤器"])}
  }
}