export default {
  "commons": {
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen & Verkaufen"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bid"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Fehler melden"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Kontos"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufträge"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Aufträge"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerben Sie sich"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkunft des Produkts"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter zu"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Konto erstellen"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche & Handel"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachtmodus"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klarer Modus"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionshistorie"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionshistorie"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoeinstellungen"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviert"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiviert"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles löschen"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisher keine Benachrichtigungen"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können keinen Rechtsklick machen"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung der Transaktion"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEHRWERTSTEUER"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie führen zu viele Suchanfragen durch"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Ihr tägliches Beratungslimit erreicht. Überprüfen Sie Ihr Konto, um nicht weiter eingeschränkt zu werden."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihre Eingabe :"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler, versuchen Sie es später noch einmal"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte melden Sie sich erneut an"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihre E-Mail-Adresse und Telefonnummer, bevor Sie diese Aktion durchführen."])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht berechtigt, diese Aktion durchzuführen"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service nicht verfügbar, bitte wenden Sie sich an den Kundenservice"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Sprache"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polnisch"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tschechisch"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dänisch"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griechisch"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungarisch"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanisch"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreanisch"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugiesisch"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RU"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russisch"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowakisch"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinesisch"])}
    },
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Spiel ablehnen"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufdatum"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis inkl. MwSt."])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie, ob alle Pflichtfelder korrekt ausgefüllt wurden."])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Problem melden"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käufer"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code gesendet"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung, der Code ist nur 15 Minuten gültig"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionell"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelperson"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie Ihre Eingabe oder wenden Sie sich an den Kundenservice."])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Kopie"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern:"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max :"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen über 18 Jahre alt sein, um diese Seite zu besuchen."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie Ihr Alter, um einzutreten."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin über 18 Jahre alt"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin unter 18 Jahre alt"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch den Zugriff auf diese Seite akzeptieren Sie die"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die Datenschutzrichtlinie."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Revolution an sich"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Referenz zum Kaufen, Verkaufen und Handeln von Spirituosen"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ob Whisky-Liebhaber, Cognac-Sammler oder Profi auf der Suche nach Perfektion - The Exchange Platform ist für Sie. Höchste Sicherheit, volle Transparenz und niedrige Gebühren: Entdecken Sie den Handel mit Spirituosen in aller Ruhe."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichere Transaktionen"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ermäßigte Gebühren 2,5% inkl. Steuern"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlose Konten 100% verifiziert"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ob Privatperson oder Profi, erkunden Sie unser Aggregat aus +70 Preisquellen für Tausende von Spirituosenreferenzen!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Ihr kostenloses Konto"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen und Verkaufen"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit The Exchange Platform verkaufen oder kaufen Sie laufend. Greifen Sie mithilfe unserer standardisierten Referenzen problemlos auf alle verfügbaren Angebote zu. Finden Sie, was Sie suchen, oder verbreiten Sie Ihr Interesse, matchen Sie die Suchanfragen der Kontrahenten, sparen Sie Zeit und handeln Sie zum besten Preis!"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind die einzige Plattform, die die Möglichkeit bietet, Flaschen mithilfe unserer Lösung für besichertes Bargeld zu tauschen. Es gibt kein Gegenparteirisiko, Ihr Geld ist auf der Plattform bis zur jeweiligen Lieferung der Flaschen sicher."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockhandel"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichern Sie eine Kauf- oder Verkaufstransaktion über Block Trade, eine exklusive Funktion von The Exchange Platform, die es ermöglicht, für jede Transaktion einen eindeutigen Link zu generieren."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio-Management"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die erfahrensten Sammler und Investoren bietet The Exchange Platform ein Portfoliomanagement-Tool, mit dem Sie Ihre Positionen und deren Werte mithilfe von Live-Preisen von der Plattform und unseren historischen Daten überwachen können. Schluss mit der ewigen Frage nach dem Wert Ihrer Flaschen, haben Sie eine objektive und agnostische Sicht wie nie zuvor."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische Preisdaten"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben die umfassendste Preishistorie für alle möglichen Spirituosen zusammengestellt, mit Preisen bis zurück ins Jahr 2002! Das Ganze ist standardisiert und in 18 verschiedenen Währungen zugänglich."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Garantie für Vertrauen"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungen werden über unseren Zahlungsdienstleister Mangopay abgewickelt, um sicherzustellen, dass die Transaktionen reibungslos ablaufen."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrigste Gebühren auf dem Markt"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir berechnen die niedrigsten Gebühren auf dem Markt, 2,5% inkl. Steuern für Käufer und Verkäufer, keine Listing- oder Reservegebühren. Sie zahlen nur, wenn es zu einer tatsächlichen Transaktion kommt."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prä-transaktionale Anonymität"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal, ob Sie Privatperson oder Profi sind, unsere Plattform ist dank ihrer Anonymität vor der Transaktion die Lösung für alle aktuellen Probleme."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da unser Zahlungsdienstleister die Anmeldungen vor der Kontobestätigung kontrolliert, können keine Phantomkonten, Scammer oder Bots auf die Plattform gelangen. Die Nachvollziehbarkeit und die Sicherheit sind absolut gewährleistet."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein maßgeschneidertes Angebot für Ihren Gebrauch"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treten Sie einer Gemeinschaft bei, um Ihre Spirituosen zu finden, zu verwalten und zu handeln!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Spirituose"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasche"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, Name, EAN, Schlüsselwörter..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche innerhalb von"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading ohne Abonnement zugänglich!"])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungen"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter zurücksetzen"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphabetisch sortieren"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termine"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Spirituose"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Veranstaltungen filtern"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auf"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bevorstehende Veranstaltung"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommende Veranstaltungen"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresabonnements"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährliche Gebühren"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatliche Gebühren"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Monat geschenkt auf eine jährliche Zahlung"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privates Angebot"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionelles Angebot"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium historische Preise"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Nutzer, die vollständige historische Daten ohne Zeitverzögerung wünschen."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Monat"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Jahr"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach Art der Spirituose"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang zu allen Referenzen"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische Preise ohne Zeitverzögerung"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Statistiken"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spirituosen abonnieren"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Spirituose zur Auswahl :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weine"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliches Modul"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für erfahrene Sammler, die sich mehr Daten und ein echtes Steuerungsinstrument wünschen."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen Sie sich einer Gemeinschaft von Spezialisten an, um Ihre Spirituosen zu analysieren, zu verwalten und zu handeln!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Verwaltung des Portfeuilles"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Angebot wählen"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Warenkorb legen"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenkorb"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatliche Zahlung"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährliche Zahlung"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischensumme"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Probenahme"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEHRWERTSTEUER (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung vornehmen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Abonnement ist bereits in Ihrem Warenkorb"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind bereits Abonnent"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Warenkorb ist leer"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zu den Abonnements"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktinformationen"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsstellung"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Transaktionen innerhalb von The Exchange Platform werden über unseren Zahlungsdienstleister Mangopay abgewickelt."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkarte"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartennummer"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name auf der Zahlungskarte"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufdatum (MM/YY)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitscode"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf abschließen"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demnächst verfügbar"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung der Zahlung"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufkommission"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrwertsteuer Kaufkommission"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnent"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Verifizierungscode der Karte (auf der Rückseite der Karte, meist 3 Ziffern"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Stornierung wurde berücksichtigt"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Stornierung ist fehlgeschlagen, bitte wenden Sie sich an den Kundenservice"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei The Exchange Platform"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greifen Sie auf einen kontinuierlichen, sicheren und standardisierten Transaktionsraum zu."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meinen Benutzernamen speichern"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen des Passworts"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre E-Mail-Adresse ein"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen des Passworts"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung des Passworts"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierungscode"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte Authentifizierung"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Code ein, den Sie per E-Mail oder Telefon erhalten haben"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort muss 7 Zeichen mit mindestens einem Großbuchstaben enthalten."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie alle Felder korrekt aus"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen gültigen Code ein"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Konto erstellen"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalität"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer, Straße"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und die"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von The Exchange Platform."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für den Newsletter anmelden"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse ein"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein kostenloses Konto erstellen und fortfahren"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Anmeldung abschließen"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie ein verifiziertes Konto"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Unternehmens"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständige Adresse"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftliche E-Mail"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Unternehmens"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmens-Nr."])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrwertsteuer-Nr."])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Unternehmen"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Informationen"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC-Check"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankverbindung"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankdaten"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greifen Sie auf einen kontinuierlichen, sicheren und standardisierten Transaktionsraum zu."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihren Verwendungszweck"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kontotyp kann nach Abschluss der Anmeldung nicht mehr geändert werden."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatperson"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionell"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto zur persönlichen Nutzung"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiziertes Geschäftskonto"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Informationen"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie alle unten stehenden Felder aus"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privates Konto"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmenskonto"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufliche Kategorie"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtliche Form"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftliche Eigentümer, die mehr als 25% halten ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte machen Sie Angaben zu anderen wirtschaftlichen Eigentümern, die mehr als 25% der Anteile halten."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geboren am"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnhaft in"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" und Nationalität"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 8 Zeichen enthalten, darunter einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung Ihrer E-Mail und Telefon"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den per E-Mail erhaltenen Code ein"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Code ein, den Sie per Telefon erhalten haben"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde eine E-Mail an Sie gesendet."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde eine SMS an Sie gesendet."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie den Code nicht erhalten?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleiten"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigt"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten auf Bestätigung"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen gültigen Code ein"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlichen Glückwunsch, Sie haben bereits jetzt kostenlosen Zugang zu vielen Funktionen der Website!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie Ihre Identität jetzt überprüfen lassen, um das volle Erlebnis zu genießen?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein verifiziertes Konto erhalten"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos ein verifiziertes Konto erhalten"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung Ihrer Identität"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Art des Dokuments"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätskarte"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Foto aus Ihrem Reisepass importieren"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren Sie ein Foto der Vorder- und Rückseite Ihres Personalausweises"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klare und lesbare Datei"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateigröße max. 7 MB"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format JPG, JPEG oder PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Überprüfung einreichen"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die durchschnittliche Verifizierungszeit beträgt etwa 24 Stunden."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeschränkte Funktionalität"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen Sie die Registrierung ab und greifen Sie auf alle Funktionen zu."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesendete Dokumente"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird überprüft"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nach Flaschen suchen und historische Preise einsehen."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können noch nicht auf den Transaktionsbereich zugreifen oder Abonnements abschließen."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesendet am"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszug aus dem Handelsregister (Kbis) oder ein gleichwertiges Dokument, das nicht älter als drei Monate ist"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopie der Satzung"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepass oder Personalausweis mit Vorder- und Rückseite"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument gesendet:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesendete Dokumente:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund für die Ablehnung:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Identität"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identität des Unternehmens"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satzung"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionärserklärung"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung der Adresse"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung vornehmen"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Kartennummer"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name und Vorname auf der ungültigen Karte"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Ablaufdatum"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Kreditkartencode"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC-Gebühren für Unternehmen"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Dokumente wurden erfolgreich übermittelt. Die Validierung wird innerhalb von 24 Stunden durchgeführt."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklärung der wirtschaftlichen Eigentümer"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die durchschnittliche Verifizierungszeit beträgt einige Minuten"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machen Sie klare und scharfe Fotos :"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achten Sie darauf, dass der Personalausweis oder Reisepass vollständig sichtbar ist, keine Schatten oder Spiegelungen aufweist und alle Details lesbar sind."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie in einem kompatiblen Format :"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie ein JPEG-, JPG- oder PNG-Format mit einer ausreichenden Auflösung, damit die Informationen deutlich sichtbar sind."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie die Vollständigkeit des Dokuments :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achten Sie darauf, dass alle notwendigen Seiten separat enthalten sind."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen 2 Belege für den Personalausweis hinzufügen"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen einen Nachweis für den Pass hinzufügen"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Dateiname oder die maximale Größe von 7 MB pro Dokument wurde überschritten."])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankverbindung"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre Bankdaten ein, um das Geld für Ihre Verkäufe zu erhalten."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Daten werden von unserem Kundenservice zur Bestätigung vorgelegt. Sie erhalten eine E-Mail, sobald Ihre Bankdaten validiert wurden."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, Routingnummer..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ausfüllen"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Transaktionen innerhalb von The Exchange Platform werden über unseren Zahlungsdienstleister Mangopay abgewickelt."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Bankdaten wurden gespeichert"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Bankdaten wurden bereits gesendet"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlichen Glückwunsch! Ihr Konto wurde nun verifiziert."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaschen durchsuchen"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang zu meinem Portfolio"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver unbegrenzter Modus"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nun auf den vollen Funktionsumfang zugreifen."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin damit einverstanden, Nachrichten von The Exchange Platform unter der oben angegebenen Telefonnummer zu erhalten. Es können Datengebühren anfallen, antworten Sie mit STOP, um sich abzumelden."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie die 2FA-SMS ablehnen, können Sie Ihr Konto nicht bestätigen. Sie können sich immer abmelden, nachdem Sie Ihre Telefonnummer über unser 2FA-SMS-System bestätigt haben."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsort"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsland"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET-Nr."])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcode scannen oder eintippen"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neueste Suchanfragen"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle löschen"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angezeigtes Produkt"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angezeigte Produkte"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt gefunden mit dieser Suche"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte, die mit dieser Suche gefunden wurden"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt gefunden"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gefundene Produkte"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Flasche?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern nach"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle anzeigen"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockhandel"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Flaschen gefunden, die Ihrer Suche entsprechen."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchverlauf ansehen"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein Konto, um einen Zugang ohne Ergebnisbeschränkung freizuschalten."])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos anmelden"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung für diese Funktion erforderlich"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Flasche"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Flasche"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ggf. die Daten der fehlenden Flasche an"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakultativ"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse gefunden."])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können dort beschreiben, wo die Gründe für die Ablehnung"])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Aufträge"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite Abfüller"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Kombinationen von Schlüsselwörtern verwenden."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Suche ist nicht empfindlich gegen Groß- und Kleinschreibung."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Angabe \"Jahre\" ist nicht erforderlich."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Schreiben einer allgemeinen Kategorie von Spirituosen (Rum, Whisky, Cognac...) wird zu keinem Ergebnis führen."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie, ob Sie mindestens ein Schlüsselwort in der Suchleiste haben."])}
  },
  "product_page": {
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Portfolio hinzufügen"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy/Sell"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zur Flasche"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite-Ticker"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Spirituose"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abfüller"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alter"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marke"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr der Abfüllung"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalt"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaschennummer"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der produzierten Flaschen"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktionsmethode"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status der Destillerie"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassifizierung"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Auftrag für diese Flasche"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite-Ticker"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Flasche suchen und zum Diagramm hinzufügen"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, Ticker oder Produktname"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen gesuchte Flasche wurde nicht gefunden"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quellen"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Bild für diese Referenz verfügbar"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fassnummer"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie anzeigen"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live-Orders"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Flaschen"])},
    "orders": {
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkaufen"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Order erstellen"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelperson"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit keine Aufträge."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufauftrag"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufpreis"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum und -zeit programmieren?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufauftrag erstellen"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsauftrag"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufspreis"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewicht"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos hinzufügen"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum und -zeit programmieren?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsauftrag erstellen"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich erstellte Order"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regeln, die Sie als"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeiden Sie es, das Orderbuch unnötig zu überfluten"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschränken Sie Ihre Aufträge auf echte Transaktionen."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeiden Sie wiederholte Ablehnungen"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholte Ablehnungen eines Spiels können als böswillig oder unverantwortlich wahrgenommen werden. Stellen Sie sicher, dass Sie nur Geschäfte eingehen, wenn Sie beabsichtigen, sie abzuschließen."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beachten Sie die Rückforderungsfristen"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Pakete wiederholt nicht rechtzeitig abgeholt werden können, kann dies zu Unannehmlichkeiten für den Verkäufer und zu zusätzlichen Kosten führen."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beachten Sie die Integrität des Orderbuchs"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Orderbuch zu manipulieren oder zu manipulieren, ein Interesse nach einer Matchablehnung zu deaktivieren usw. gelten als irreführende Praktiken. Achten Sie auf die Transparenz Ihres Handelns."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seien Sie auf die damit verbundenen Kosten vorbereitet"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es können lokale Einfuhr- und Zollgebühren anfallen. Stellen Sie sicher, dass Sie bereit sind, diese bei Abschluss eines Geschäfts zu zahlen."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie genaue Informationen bereit"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achten Sie darauf, dass die Beschreibung der Flasche genau und wahrheitsgemäß ist. Das hilft den Käufern, eine fundierte Entscheidung zu treffen. Stellen Sie ein vollständiges Foto von vorne und hinten zur Verfügung."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halten Sie sich an die Details des Angebots"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie verpflichten sich, eine Flasche zu verkaufen, halten Sie diese Verpflichtung ein. Jedes nicht eingehaltene Angebot hat Konsequenzen für den Verkäufer."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantieren Sie die Qualität der Verpackung und ihres Inhalts"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der wiederholte Empfang von beschädigten Paketen (Beschädigung, Bruch, Leck), schlechtem Inhalt, wirkt sich auf Sie aus. Achten Sie darauf, dass Sie die Flaschen sorgfältig verpacken."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halten Sie die Versandzeiten ein"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie wiederholt nicht rechtzeitig versenden, kann dies Ihren Zugang zum Transaktionsbereich beeinträchtigen. Planen Sie entsprechend, um Verzögerungen zu vermeiden."])},
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Kaufauftrag erstellen"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Verkaufsauftrag erstellen"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Swap Taker-Order erstellen"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Swap Giver-Auftrag erstellen"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Block Trade erstellen"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewichtseinheit"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größeneinheit"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage wurde berücksichtigt. Sie werden bald wieder kontaktiert"])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz des Pakets:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihr Problem hier detailliert beschreiben, um die Lösung des Tickets zu vereinfachen."])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Erhalt des Pakets bestätigen"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Bestätigung des ordnungsgemäßen Erhalts des Pakets bestätigen Sie, dass das Produkt Ihren Erwartungen entspricht."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermögenswerte"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versenden"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geliefert"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsstreit"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beendet"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferdetails"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking-Nummer"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Paket verfolgen"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur Lieferung ansehen"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Lieferung"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte nehmen Sie Ihre Vorschläge an oder lehnen Sie sie ab, bevor Sie einen neuen Auftrag erstellen."])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Block Trade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzigartiger Link"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichere Transaktion mit unserem Zahlungsanbieter Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferschein herunterladen"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Überprüfung"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonyme Transaktion (3 EUR inkl. MwSt.)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Bilder der Flasche einfügen"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie dürfen das gleiche Bild nicht mehrfach einfügen"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Bilder unter 7mb und mit den Erweiterungen .JPG, .JPEG und .png werden akzeptiert."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Auftrag kann nicht weniger als eine Stunde gültig sein"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit keine Kaufaufträge."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit keine Verkaufsaufträge."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Käufer wird Ihre Identität nicht kennen"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung von zu Hause"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Paket wird von UPS bei Ihnen zu Hause abgeholt"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Abholung"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Stunde der Abholung"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Abholzeit"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Order entspricht nicht Ihren Länderpräferenzen oder denen des Kontrahenten"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder hinzufügen"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder Drag & Drop"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur .JPG, .PNG und .JPEG (max. 7MB pro Foto)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhältlich in Relais-Punkten"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Ihre Identität verifizieren, um auf den Transaktionsbereich zugreifen zu können."])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Identität überprüfen"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschreibung erstellen"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten eine E-Mail, wenn ein Verkaufsauftrag erstellt wird."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten eine E-Mail, wenn ein Kaufauftrag erstellt wird."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie erhalten eine E-Mail, wenn eine Order erstellt wird."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um einen Alert zu erstellen, gehen Sie auf die Produktseite Ihrer Wahl."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis (optional)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Alert wurde erfolgreich erstellt"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Erstellung Ihres Alerts"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Preis muss zwischen 50 und 30000 EUR liegen oder 0 EUR betragen."])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Benachrichtigung pausieren"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Alarm reaktivieren"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiviert"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische Preise"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Flasche hinzufügen"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Grafiken dienen nur zu Informationszwecken. Wir übernehmen keine Haftung für eventuelle Fehler."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere historischen Daten aggregieren"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aus"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verschiedene Quellen."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebühren"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnitt"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoch"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafik Linie"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerzen-Chart"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart-Optionen"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seit"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Preise werden mit einer Verzögerung von"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine historischen Preisdaten verfügbar. Falls wir sie noch nicht eingebaut haben, wenden Sie sich bitte an den Support, um sie hinzufügen zu lassen."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Ansicht"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täglich"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergleichen mit"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Flaschen ausgewählt"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Flasche ist bereits in der Grafik enthalten"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Limit von 6 Flaschen für das Diagramm erreicht"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite anzeigen"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Fehler melden"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Fehlers"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmerkungen"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakultativ"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreiben Sie das Problem hier genauer..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screenshots / Bilder"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. 2 Bilder."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie"])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Flaschen für diese Referenz gefunden."])},
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferdetails"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage wurde berücksichtigt"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Veröffentlichung"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabepreis"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käufer Match"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Match"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren und verkaufen"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meine ", _interpolate(_named("num_transac")), " aktuellen Transaktionen anzeigen"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos anzeigen"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Kaufauftrag"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käufer"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsauftrag"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufauftrag"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenpartei"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Verkaufsauftrag"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es werden keine Spiele angezeigt."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoerstellung und angebotene Funktionen für alle"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionen"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungeprüftes Konto"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiziertes Konto"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenloser und unbegrenzter Vollzugriff"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische Preise"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Visualisierung"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 Monate Aufschub"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Monate Aufschub"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 Monate verzögerte Bewertung"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Monate verzögerte Bewertung"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 eindeutige Ticker"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 einzigartige Ticker"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenbank"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 Ticker pro Tag"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Aufträge anzeigen"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein transaktionaler Zugang"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID-Verifizierung kostenlos für Privatpersonen und kostenpflichtig für Geschäftsleute. KYC wird von unserem Zahlungsdienstleister Mangopay durchgeführt."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbegrenzter Vollzugriff"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopierte ID"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import einer CSV- oder Excel-Datei"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasche"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles auswählen"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle abwählen"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach einer Flasche suchen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Sie zu führen,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei hochladen"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder Drag & Drop"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur .CSV- und .XLSX-Dateien"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ein Importmodell"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalten"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Haltedauer"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbenennen"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In einen anderen Ordner verschieben"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner umbenennen"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name des Ordners ist erforderlich"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maximale Anzahl an Zeichen darf 30 Zeichen nicht überschreiten."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabellenspalten"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen Tabelle"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles einklappen"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles aufklappen"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauftes Inventar anzeigen"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaschen"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettopreis"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttopreis"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommission"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebühren"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort der Lagerung"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besitz"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort des Kaufs"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen eines Ordners"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen einer Flasche"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des übergeordneten Ordners"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Spalten"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenfolge der Spalten"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Flaschen"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum des Kaufs"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisoptionen hinzufügen"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum des Verkaufs"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum des Handels"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Flaschen gefunden, die Ihrer Suche entsprechen."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den übergeordneten Ordner"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Ordners"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner erstellen"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasche bearbeiten"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltene Flaschen"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaschen in Ihrem Besitz"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzigartige Referenzen"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltene Flaschen multipliziert mit dem jeweiligen Kaufpreis"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltene Flaschen multipliziert mit dem Marktwert"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Wertsteigerung, Differenz zwischen Marktwert und Kaufpreis multipliziert mit der Menge."])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Rendite"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Jahresrendite"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittspreis aller Flaschen"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Dauer, während der ein Buyer's Hold im Portfolio gehalten wird."])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre bisher gekauften Flaschen"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre bisher verkauften Flaschen"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisherige Gesamtausgaben"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisheriger Gesamtumsatz"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkumulierter Gewinn vom ersten Tag bis heute"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realisierter Gewinn im ausgewählten Zeitraum"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendite ab dem ersten Tag berechnet (nur verkaufte Flaschen berücksichtigen)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektive Jahresrendite (verkaufte Flaschen)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzigartige Referenzen"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangswert Position"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Rendite"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annualisierte Rendite"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realisierte historische Jahresrendite"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historische realisierte Rendite"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher Preis"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historischer Überblick"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekaufte Flaschen"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzigartiger Ticker"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latenter Gewinn"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufte Flaschen"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben insgesamt"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realisierter Gesamtgewinn"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realisierter Gewinn (Zeitraum)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realisierte historische Rendite"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtumsatz"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum des Kaufs/Verkaufs"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettopreis"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis ohne Gebühren"])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis mit Gebühren"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise mit MwSt."])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettopreis"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brutto-Kaufpreis"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttoverkaufspreis"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort der Lagerung"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkaufen"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zum Verweis"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diesen Ordner löschen möchten?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel-Datei"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipps für Ihre Excel-Datei"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angezeigte Spalten"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles auswählen"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendeter Raum"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner erstellen"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Flasche hinzufügen"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei importieren"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Flasche"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Flaschen"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Referenz"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Referenzen"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Dossier"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In einen Ordner verschieben"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaschen im Dossier"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenzen im Dossier"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In einen Ordner verschieben"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebühren"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besitz (Tag)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort des Kaufs"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort des Verkaufs"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufte Menge"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WAB-Preis"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WASD-Preis"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlageklasse"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Produktbeschreibung gehen"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Preis"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilität"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles abwählen"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausgewählte Flasche(n)"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschieben"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasche löschen"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Portfolio ist derzeit nur auf dem PC zugänglich, wird aber bald auch auf Mobilgeräten verfügbar sein."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilität des Portfolios"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typische Volatilität von Spirituosen"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destillerie-Volatilität"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage-Volatilität"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilität Alter"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium-Portfolio bald verfügbar"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details ansehen"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Produktseite"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelltes Dossier"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dossier wurde geändert"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ordner wurde gelöscht"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Erstellung des Dossiers"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Flasche wurde hinzugefügt"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Flasche wurde geändert"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Flasche wurde verschoben"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei erfolgreich importiert"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originaldossier :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie werden diesen Ordner sowie alle darin befindlichen Flaschen verschieben."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Akte wurde verschoben"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen nicht möglich, da sich Flaschen in diesem Ordner befinden"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Angebot senden"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur .jpg-, .jpeg- und .png-Dateien unter 3Mb sind erlaubt."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details der Transaktion"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung des Auftrags"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandkosten"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufen"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandkosten"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsgebühren"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dont TVA to"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS-Lieferkosten"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittlere Zahlungsgebühren"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS-Versicherung"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS zu Hause"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS an einem Relais-Punkt"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Abholstellen"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Adresse für meine nächsten Einkäufe speichern"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Transaktion ist fehlgeschlagen. Bitte versuchen Sie es erneut"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Transaktion wurde erfolgreich abgeschlossen"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Zahlung wird überprüft"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte haben Sie ein paar Sekunden Geduld"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter Konto"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung von Abonnements"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Abonnements"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungshistorie"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen & Verkaufen"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankverbindung"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit und 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalität"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eine weitere Lieferadresse hinzu"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadresse"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadresse löschen"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesendet"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht angegeben"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesendet am"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB ändern"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte Authentifizierung"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie bei jedem Login und bei jeder Änderung an Ihrem Konto einen Code."])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte Authentifizierung per E-Mail"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte Authentifizierung per SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neueste Verbindungen"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen Sie sich den Verlauf der letzten 10 Verbindungen zu Ihrem Konto auf allen Geräten an."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter erhalten"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debitkarte Nr."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzugefügt am"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfällt am"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Bankkarte ändern"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch keine Zahlungskarte angegeben."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Adresse auf Standard setzen"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorzugte Lieferadresse"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der E-Mail-Adresse"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle E-Mail-Adresse"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue E-Mail-Adresse"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Telefonnummer"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Telefonnummer"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Telefonnummer"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen einer Lieferadresse"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche E-Mail"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon falsch"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben diese E-Mail bereits"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben diese Telefonnummer bereits"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben des aktuellen Monats"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnent seit"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind kein Abonnent"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresabonnements"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Abbuchung am"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonniert bis"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement kündigen"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkunden Sie die verfügbaren Abonnements für historische Preise"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement kündigen"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Abonnement endet am"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betroffenes Abonnement"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornierung bestätigen"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement fortsetzen"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Abonnement fortsetzen"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die nächste Auszahlung erfolgt am"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betroffenes Abonnement"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zahlung wird jeden"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Preis von"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Aufschwung bestätigen"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingestellt bis"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium historische Preise"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungshistorie"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle herunterladen"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung herunterladen"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Rechnung"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferdetails"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket verfolgen"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschätzte Lieferung"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsnummer"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den per E-Mail erhaltenen Code ein"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Code ein, den Sie per Telefon erhalten haben"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue E-Mail"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Telefon"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den per E-Mail erhaltenen Code ein"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Code ein, den Sie per Telefon erhalten haben"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungs- und Lieferadresse"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Präferenzen wurden erfolgreich gespeichert"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsort"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsland"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen eines Kaufauftrags"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen eines Verkaufsauftrags"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen einer Swap-Order"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen einer Blocktrade-Order"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Käufer lehnte das Spiel ab"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsauftrag wartet auf Bestätigung"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben Sie den Empfang von SMS (Kontobestätigung und/oder 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikationspräferenzen"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS-Relaispunkt"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufoptionen"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsoptionen"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbarkeit beim Kauf"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbarkeit beim Verkauf"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszuschließende Länder"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu berücksichtigende Länder"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Kaufpräferenzen für den Verkauf kopieren"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Änderungen wurden berücksichtigt"])}
    }
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soziale Netzwerke"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtliches"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe-Center"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekrutierung"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Fehler melden"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status der Dienstleistungen"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtliche Hinweise"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB & AGB"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung von Cookies"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Frankreich registriertes Unternehmen"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandkosten"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuern"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung des Auftrags"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauft von :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandkosten"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebühren für den Verkauf"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag"])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Kontos"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Aufträge"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leitung"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Erstellung"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufauftrag ändern"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsauftrag ändern"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade bearbeiten"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftrag ändern Swap"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöschte Aufträge anzeigen"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich besitze eine"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["im Austausch für"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ich tauschen möchte gegen"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich geänderte Order"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich erstellte Order"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Aufträge :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel, Preis..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund für die Ablehnung"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinn"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhängige Rechtsstreitigkeiten"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Streitfall melden"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Transaktionen durchgeführt."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter zurücksetzen"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlicht am :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geändert am :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel filtern"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Artikel gefunden."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarer Betrag"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Warteschleife"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung durchführen"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungen"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung erfolgt"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Transaktionen anzuzeigen."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleitung"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingehende Überweisung"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgehende Überweisung"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer-Zahlung"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abhebung auf mein Bankkonto"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abheben von verfügbarem Geld"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abhebung bestätigen"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überweisung auf Ihr Bankkonto wurde erfolgreich durchgeführt"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überweisung auf Ihr Konto ist fehlgeschlagen. Wenn das Problem weiterhin besteht, wenden Sie sich an den Kundenservice"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Wallet (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restbetrag nach der Transaktion :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung erhalten"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff auf meine Transaktionshistorie"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geld direkt auf Ihr Wallet überweisen, um Gebühren für Zahlungsmittel zu vermeiden"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümer :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN:"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC:"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Gelder sind innerhalb von 2-3 Werktagen verfügbar."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich kopiertes Element"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versorgung des Wallets"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Filter"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die Suche ohne Schlüsselwörter sind 2 Filter erforderlich"])}
  }
}